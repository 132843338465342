import React from "react"
import HeadSection from "../components/new-design/HeadSection"
import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"
export default function about({ location }) {
  return (
    <Layout>
      <Seo
        title="About Us"
        pathname={location.pathname}
        description="Having fallen in love with the island more than 20 years ago and
          quickly making it their home, the High Life Team have a comprehensive
          knowledge of the island, making them the perfect agent to help plan
          your Ibiza vacation."
      />
      <HeadSection title="About Us" />
      <Content />
    </Layout>
  )
}

const Content = () => {
  return (
    <div className="p-2 lg:p-8 container mx-auto py-12 md:py-32">
      {/* <h1 className="pb-[30px]">About Us</h1> */}
      <div className="grid gap-2">
        <p className="font-property text-sm">
          The Book Ibiza Team has extensive experience of the island, having
          fallen in love with it more than 15 years ago and swiftly made it
          their home. They are the ideal agency to assist you in planning your
          Ibiza trip. The team curates bespoke stays, adapted to their
          customers' preferences, with access to some of the island's most
          exclusive villas and close relationships with the island's most elite
          venues.
        </p>
        <p className="font-property text-sm">
          The perfect stay starts with the perfect villa. <br />
          Our rentals team inspects each property in our portfolio to verify
          that it meets our high standards. Once we've verified your villa,
          we'll work closely with you to understand your needs and assist you in
          planning any element of your stay so you don't have to.
        </p>
      </div>
    </div>
  )
}
